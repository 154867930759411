.bx-wrapper {
  box-shadow: none;
  border: 0;
  background: none;
  margin: 0;
}

.bx-wrapper .bx-loading {
  background: none;
}

.bx-wrapper .bx-prev {
  background: none;
  left: 0;
}

.bx-wrapper .bx-next {
  background: none;
  right: 0;
}

.bx-wrapper .bx-controls-direction a {
  width: 25px;
  height: 45px;
  @include media-breakpoint-up(md) {
    width: 40px;
    height: 60px;
  }

  &::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    border-top: 1px solid $primary;
    border-right: 1px solid $primary;
    transform: rotate(45deg);
    transform-origin: center center;
    left: 5px;
    top: 18px;
    @include media-breakpoint-up(md) {
      width: 20px;
      height: 20px;
    }
  }

  &.bx-prev::after {
    transform: rotate(-135deg);
    left: auto;
    right: 5px;
  }
}

.bx-wrapper .bx-controls-auto .bx-start {
  background: none;
}

.bx-wrapper .bx-controls-auto .bx-stop {
  background: none;
}

//* PAGER */
.bx-wrapper .bx-pager {
  padding-top: 0;
}

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  bottom: 15px;
}

.bx-wrapper .bx-pager.bx-default-pager a {
  background: white;
  text-indent: -9999px;
  display: block;
  width: 25px;
  height: 8px;
  margin: 0 3px;
  outline: 0;
  border-radius: 0;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:focus {
  background: $primary;
}
