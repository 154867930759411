.dropdown {
  .dropdown-menu {
    transition: opacity .3s 0s, visibility 0s 0s;
    opacity: 0;
    display: block;
    visibility: hidden;
  }

  &.show .dropdown-menu {
    opacity: 1;
    visibility: visible;
  }
}

.dropdown.show .dropdown-toggle:focus {
  box-shadow: none;
}
