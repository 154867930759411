.navbar-logo img {
  width: 120px;
  padding: 15px 0;
}

.navbar {
  background-color: rgba($white, 0.5);
  transition: background-color .3s 0s, transform .3s cubic-bezier(.16, .68, .43, .99);
  border-bottom: 1px solid rgba($gray-200, 0.8);
  position: relative;
  z-index: $zindex-fixed;
  left: 0;
  right: 0;

  // selected & hover state
  &.navbar-focused {
    background-color: $white;
    border-bottom-color: $gray-200;
  }

  // Link height 100%
  // .nav-item,
  // .nav-link {
  //   height: 100%;
  // }

  .nav-link {
    display: flex;
    align-items: center;
  }
}

.navbar .nav-link {
  padding: $nav-link-padding-y ($nav-link-padding-x * 0.5);
  @include media-breakpoint-up(lg) {
    padding: $nav-link-padding-y $nav-link-padding-x;
  }
}

// Active Link
.navbar .nav-link.active {
  color: $white;
  position: relative;

  &::before {
    content: "";
    background-color: $primary;
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
    height: 2em;
    top: 50%;
    transform: translateY(-50%);
  }
}

// navbar height
.navbar-wrapper {
  height: map-get($navbar-height, sm);
  @include media-breakpoint-up(xl) {
    height: map-get($navbar-height, xl);
  }
}

// navbar language
.language-select {
  position: absolute;
  top: -1.6rem;
  right: 1.6rem;

  @include media-breakpoint-up(xl) {
    top: -2.5rem;
    right: 2.5rem;
  }

  a {
    margin-left: 0.5rem;
    padding: 0.3rem 0;

    &:hover {
      text-decoration: none;
    }

    &.active,
    &:hover {
      font-weight: 900;
    }
   }
}

// navbar search
.navbar-search {

  &.navbar-search-focused {
    .form-control {
      width: 7rem;
    }
  }

  .form-control {
    background: transparent;
    width: 4.5rem;
    padding: 0.1rem 0.5rem;
    border-bottom: 2px solid $primary;
    font-size: 0.8rem;
    transition: width 0.3s 0s;

    &:focus::placeholder {
      color: $gray-200;
    }

    &::placeholder {
      color: $body-color;
    }
  }

  .btn-default {
    background: transparent;
    border: 0;
    padding: 0;

    img {
      width: 1rem;
    }
  }
}

// Media Queries at breakpoint
@include breakpoint {
  .navbar {
    position: absolute;

    body.noheader-template & {
      position: relative;
      @include breakpoint {
        margin-top: 1.6rem;
      }
      @include media-breakpoint-up(xl) {
        margin-top: 2.5rem;
      }
    }
  }

  .navbar-logo img {
    padding: 0;
    width: 160px;
  }
}

// Hiding elements
.navbar-nav,
.navbar-search,
.language-select {
  display: none;
  visibility: hidden;

  @include breakpoint {
    display: block;
    visibility: visible;
  }
}

@include max-breakpoint {
  .navbar-wrapper {
    height: map-get($navbar-height, breakpoint);
  }

  .navbar-logo {
    max-width: 70%;
    flex-basis: 70%;
    text-align: right;
  }
}
// Media Queries at breakpoint [end]

// Menu Icon
.menu-icon {
  display: block;
  position: relative;
  cursor: pointer;
  z-index: 999;
  top: 0;
  color: $primary;
  letter-spacing: 0.15em;
  font-size: 14px;
  transform: translateZ(0);
  height: 24px;
  width: 24px;

  &:hover .box-shadow-menu::before {
    background: $gray-900;
    box-shadow: 0 6px 0 0 $gray-900, 0 12px 0 0 $gray-900;
  }
}

.box-shadow-menu {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    width: 20px;
    height: 2px;
    background: $primary;
    z-index: 999;
    box-shadow: 0 6px 0 0 $primary, 0 12px 0 0 $primary;
  }
}

@include breakpoint {
  .navbar-menu-icon {
    display: none;
    visibility: hidden;
  }
}
