// Spacing
.product-info > [class*="product-"] {
  margin-bottom: 2rem;
  @include media-breakpoint-up(md) {
    margin-bottom: 2.75rem;
  }
}

.product-gallery {
  .main-img {
    margin-bottom: 1rem;
  }

  //isotope
  .grid-sizer,
  .grid-item {
    width: 31.33333%;

    @include media-breakpoint-up(md) {
      width: 22.75%;


      &.grid-item {
        width: 48.5%;
      }

      &.grid-item--width-1 {
        width: 22.75%;
      }
    }

  }
}

.product-info .product-header {
  .product-title {
    display: inline-block;
    margin-bottom: 0;
  }

  .product-ean {
    font-size: 1.1rem;
    margin-left: 0.5rem;
  }

  .product-category {
    @include monospace(0.8rem);
  }
}

.product-features ul,
.product-optional-features ul {
  padding-left: 0;
  list-style: none;
}

.product-size {
  .size-item {
    margin-right: 1.75rem;
    width: 22px;

    img {
      width: 100%;
    }

    span {
      display: block;
      font-size: 0.73rem;
    }
  }
}

.product-features ul li::before,
.product-optional-features ul li::before {
  content: "\2713";
  margin-right: 0.5rem;
  font-size: 18px;
  line-height: 1.1;
}

.product-optional-features ul li::before {
  content: "\002b";
}

.product-download {
  .btn {
    width: 100%;
    max-width: 23rem;
    display: block;
    margin-bottom: 1rem;

    i {
      margin-left: 0.5rem;
    }
  }
}

.related-item .grid-item-overlay-title {
  text-align: center;
}
