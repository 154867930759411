.section--related {
  .bx-wrapper {
    margin-left: 0;
    margin-right: 0;
  }

  .related-item {
    width: 100%;
    overflow: hidden;
  }

  .grid-item-img {
    position: relative;
    height: 0;
    overflow: hidden;
    width: 100%;
    padding-bottom: percentage(250/230);
    background-color: $secondary;
    background-size: contain;
    background-position: center bottom;
    background-repeat: no-repeat;
  }

  // Slider controls
  .bx-wrapper .bx-controls-direction a {
    height: 100%;
    background: rgba($white, 0.65);
    top: 0;
    bottom: 0;
    margin: 0;
    width: 30px;

    &::after {
      top: 50%;
    }
  }
}
