.secondary-nav-grid .grid-nav-item {
  overflow: hidden;
  @include force-hardware-acceleration;

  &::after,
  &::before,
  .product-category-img {
    @include force-hardware-acceleration;
    transition: all 0.5s $easing-custom;
  }

  &:hover {
    &::after {
      opacity: 1;
    }

    &::before {
      opacity: 0.085;
    }

    .product-category-img {
      @include force-hardware-acceleration;
      transform: scale(1.075);
      opacity: 0.5;
    }
  }
}

// Header Slider [start]
.header-item {
  @include force-hardware-acceleration;
}

// Title
.header-item-title {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s $easing-custom 0s;

  .active-slide & {
    opacity: 1;
    transform: translateY(0%);
  }
}

// Horizontal line
.header-item-title {
  &::after {
    max-width: 0%;
    transition: all 0.65s $easing-custom 0.2s;
  }

  .active-slide &::after {
    max-width: 150%;
  }
}

// Category
.header-item-cat {
  opacity: 0;
  transform: translateX(15px);
  transition: all 0.3s linear 0.55s;

  .active-slide & {
    opacity: 1;
    transform: translateX(0);
  }
}

// Slider number
.header-item-text .pager {
  opacity: 0;
  transition: all 0.3s linear 0.95s;

  .active-slide & {
    opacity: 1;
  }
}


// Header Slider [end]

// Isotope Grid [start]
.grid-item {
  // opacity: 0.1;
  // filter: grayscale(100%);
  // transition: opacity .5s, filter 0.8s;
  //
  // &.img-loaded {
  //   opacity: 1;
  //   filter: grayscale(0);
  // }
}

.grid-item-link:hover {
  .grid-item-img img {
    transform: scale(1.05);
  }

  .grid-item-overlay {
    opacity: 1;
  }

  .grid-item-title {
    transform: translateX(-60%);
    opacity: 0;
  }

  .grid-item-badge {
    transform: translateX(60%);
    opacity: 0;
  }

  .grid-item-overlay-ean,
  .grid-item-overlay-cat {
    transform: translateY(0);
    opacity: 1;
  }
}

.grid-item-overlay,
.grid-item-img img,
.grid-item-overlay-ean,
.grid-item-overlay-cat {
  @include force-hardware-acceleration;
  transition: all 0.5s $easing-custom;
}

.grid-item-title,
.grid-item-badge {
  @include force-hardware-acceleration;
  transition: opacity 0.3s $easing-custom, transform 0.5s $easing-custom;
}

.grid-item-overlay-ean,
.grid-item-overlay-cat {
  opacity: 0;
}

.grid-item-overlay-ean {
  transform: translateY(-80%);
}

.grid-item-overlay-cat {
  transform: translateY(80%);
}



// Isotope Grid [end]
