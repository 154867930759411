.news-item {
  margin-bottom: 2rem;
}

.news-meta {
  @include monospace(11px);
  color: $gray-600;

  .news-cat {
    font-weight: 700;
    text-transform: uppercase;

    &::after {
      content: "//";
      padding-left: 6px;
      padding-right: 6px;
    }
  }
}

.news-heading {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}
