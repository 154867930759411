img {
	max-width: 100%;
	height: auto;
}

.container-max-width {
	max-width: $container-max-width;
  @include media-breakpoint-up(md) {
    padding-right: ($grid-gutter-width * 1.2);
    padding-left: ($grid-gutter-width * 1.2);
  }
  @include media-breakpoint-up(xl) {
    padding-right: ($grid-gutter-width * 1.5);
    padding-left: ($grid-gutter-width * 1.5);
  }
}

.employee-image {
  padding-bottom: 20px;
}

%clearfix {
  *zoom: 1;

  &::after,
  &::before {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.section {
  padding: 2rem 0;
  @include media-breakpoint-up(md) {
		padding: 3.8rem 0;
	}
	@include media-breakpoint-up(xl) {
		padding: 4.25rem 0;
	}

  &.section--border-top {
    border-top: 1px solid rgba($gray-200, 0.8);
  }
  &.section--isotope {
    text-align: center;
  }
}

img[data-sizes="auto"] {
  display: block; width: 100%;
}
