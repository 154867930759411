.navbar-nav {
  position: static;
  height: 100%;
}

.navbar-nav > .nav {
  height: 100%;
  align-items: center;
  flex-wrap: nowrap;
}


// secondary nav
.secondary-nav {
  @include responsive-size($responsive-padding);
  border-bottom: 1px solid $gray-200;
  display: block;
  position: absolute;
  background-color: $white;
  width: 100vw;
  z-index: $zindex-fixed;
  left: 0;
  top: map-get($navbar-height, sm) + 1px;
  transition: opacity .3s 0s, visibility 0s 0s;

  @include media-breakpoint-up(xl) {
    top: map-get($navbar-height, xl) + 1px;
  }

  &.is-hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s 0s, visibility 0s .3s;
  }
}

// moving arrow
.secondary-nav-arrow {
  @include triangle(top, $gray-200, 9px);
  height: 15px;
  width: 15px;
  position: absolute;
  top: map-get($navbar-height, sm) - 15px;
  transform: translateX(-50%);
  transition: left .35s ease-out, opacity .3s 0s, visibility 0s .3s;
  opacity: 0;
  z-index: 999999;

  @include media-breakpoint-up(xl) {
    top: map-get($navbar-height, xl) - 15px;
  }

  .navbar-focused & {
    opacity: 1;
    visibility: visible;
  }
}

// close button
.secondary-nav-close-btn {
  position: absolute;
  top: 1.5rem;
  right: 2.5rem;
  font-size: 1.5rem;
  cursor: pointer;
}

.secondary-nav-links {
  max-width: $container-max-width + 200;
  // margin-bottom: 2rem;

  a.btn {
    color: $primary;
    // border: 1px solid rgba($gray-900, 0.2);
    border: 0 none;
    padding: 1.5rem 1rem;

    display: inline-block;
    font-weight: $font-weight-normal;
    background: #e5e9ee;
    width: 114px;
    //padding-top: $btn-padding-y * 1.7;
    //padding-bottom: $btn-padding-y * 1.7;

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
}

@include media-breakpoint-up(xl) {
  .secondary-nav-links {

    a.btn {
      width: 130px;
    }
  }

}


.secondary-nav-title {
  @include heading(3rem);
  margin-bottom: 1rem;
}

.secondary-nav-subtitle {
  margin: 1.5rem 0 0.5rem;
}

// Product Category Grid
.secondary-nav-grid {
  margin-bottom: 2rem;

  .grid-nav-item {
    margin: 8px;
    position: relative;
    text-align: left;
    display: block;
    background-color: $black;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      border: 5px solid rgba($white, 0.2);
      opacity: 0;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 50%;
      background: $white;
      transform: skew(-40deg);
      opacity: 0;
    }

    .product-category-img {
      position: relative;
      height: 0;
      overflow: hidden;
      width: 100%;
      padding-bottom: percentage(250/200);
      background-color: $secondary;
      // background-size: cover;
      background-size: 80px auto;
      background-repeat: no-repeat;
      background-position: center 40%;
    }

    .product-category-title {
      @include heading(0.92rem);
      display: inline-block;
      // background-color: $primary;
      // color: $white;
      text-align: center;
      padding: 0.55rem;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 11%;
      z-index: 100;
    }
  }
}


// Nav dropdown
.secondary-nav-dropdown {
  .dropdown {
    margin-left: $spacer / 2;
    margin-right: $spacer / 2;
  }

  .dropdown-toggle {
    min-width: 10rem;
  }
}

// Product Grid width
.secondary-nav-grid .grid-nav-item {
  // min-width: 167px;
  width: calc(20% - 16px);
  @include media-breakpoint-up(xl) {
    // min-width: 200px;
    width: calc(10% - 16px);
  }
}
