.filter-button-group {
  margin-top: 0.75rem;
}

// Filter Buttons
.filter-button-group {
  .btn {
    border: 1px solid rgba($gray-900, 0.2);
    display: inline-block;
    font-weight: $font-weight-normal;
    background: transparent;
    padding-top: $btn-padding-y * 1.7;
    padding-bottom: $btn-padding-y * 1.7;
    margin-bottom: 0.45rem;
    // margin-right: 0.45rem;
    transition: all 0.3s;
    position: relative;
    min-width: 48.5%;

    &:hover {
      border: 1px solid rgba($gray-900, 1);
    }

    &.is-checked {
      background: $primary;
      color: $white;
    }

    span {
      @include monospace(0.7rem);
      // margin-right: 0.25rem;
      position: absolute;
      left: $btn-padding-x;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.5;
      display: none;
    }

    span.close {
      right: $btn-padding-x;
      left: auto;
      font-size: 1rem;
    }
  }

  // wide Buttons
  &.filter-button-group--productcat .btn {
    display: block;
    min-width: 48.5%;
    margin-bottom: 0.8rem;

    @include media-breakpoint-up(md) {
      min-width: 100%;
    }

    @include media-breakpoint-up(lg) {
      span {
        display: block;
      }
    }

  }
}

.sidebar .filter-container {
  display: none;
  margin-bottom: 2.5rem;
  @include media-breakpoint-up(md) {
    display: block;
  }
}

.sidebar .btn.show-filter {
  width: 100%;
  margin-bottom: 1.5rem;
}



.current-selected-descr {
  max-width: 40rem;
  margin-bottom: 3.5rem;

  h1 {
    margin-bottom: 1.5rem;
  }
}
