/*! Pushy - v1.1.0 - 2017-1-30
* Pushy is a responsive off-canvas navigation menu using CSS transforms & transitions.
* https://github.com/christophery/pushy/
* by Christopher Yee */

$menu_width: 280px;

// Menu Appearance
.pushy {
  position: fixed;
  width: $menu_width;
  height: 100%;
  top: 0;
  z-index: 9999;
  background: $primary;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  @include heading(2.5rem);
  font-size: 1.1rem;
  color: $gray-100;

  a,  li.pushy-submenu > button{
    display: block;
    color: $gray-100;
    padding: 0.7rem 0;
    text-decoration: none;
    background: none;
    border: 0;
  }


  &.pushy-left {
    left: 0;
  }

  &.pushy-right {
    right: 0;
  }
}

.pushy-content {
  visibility: hidden;
}

// Menu Movement
.pushy-left {
  transform: translate3d(unquote('-' + $menu_width), 0, 0);
}

.pushy-open-left {
  .site-container,
  .push {
    transform: translate3d($menu_width , 0, 0);
  }
}

.pushy-right {
  transform: translate3d($menu_width, 0, 0);
}

.pushy-open-right {
  .site-container,
  .push {
    transform: translate3d(unquote('-' + $menu_width) , 0, 0);
  }
}

.pushy-open-left,
.pushy-open-right {
  .pushy {
    transform: translate3d(0, 0, 0);
  }

  .pushy-content {
    visibility: visible;
  }
}

// Menu Transitions
.site-container,
.push,
.pushy {
  transition: transform 0.3s cubic-bezier(.16, .68, .43, .99);
}

.pushy-content {
  transition: visibility 0.3s cubic-bezier(.16, .68, .43, .99);
}

// Site Overlay
.site-overlay {
  display: none;
}

.pushy-open-left,
.pushy-open-right {
  .site-overlay {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9998;
    background-color: rgba($black, 0.5);
    animation: fade 500ms;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// Submenu Appearance
.pushy-submenu {
  ul,
  span {
    transition: max-height 0.5s ease-in-out, visibility 0.5s ease-in-out;

    .pushy-link {
      transition: opacity 0.5s ease-in-out;
    }
  }

  span {
    display: block;
  }

  // Submenu Icon
  > button,
  > a {
    position: relative;
  }

  > button::after,
  > a::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    transition: transform 0.5s;
    @include triangle(right, $gray-200, 7px);
  }
}

// Submenu Movement
.pushy-submenu-closed {
  ul,
  span {
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  .pushy-link {
    opacity: 0;
  }
}

.pushy-submenu-open {
  ul,
  span {
    max-height: 1000px;
    visibility: visible;
    //padding-left: 200px;
  }

  .pushy-link {
    opacity: 1;
  }

  // Submenu Icon
  a::after,
  button::after {
    transform: translateY(-50%) rotate(90deg);
  }
}
