label {
  margin-bottom: 0;
  font-size: 15px;
}

.form-control {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  font-weight: $font-weight-normal;
}

textarea.form-control {
  background-color: #f5f5f5;
}