.sidebar .section {
  padding-top: 0;
  padding-bottom: 1.5rem;
  @include media-breakpoint-up(md) {
    padding-right: $grid-gutter-width * 1.5 - ($grid-gutter-width / 2);
  }
}

.sidebar nav.nav {
  @include media-breakpoint-up(md) {
    padding-right: $grid-gutter-width * 1.5 - ($grid-gutter-width / 2);
  }

  .nav-link {
    border-bottom: 1px solid $gray-200;
    padding: 0.65rem 0.35rem;
    transition: background 0.3s;

    &:hover {
      background-color: $gray-100;
    }

    span {
      @include monospace(0.7rem);
      opacity: 0.5;
      padding-right: 0.35rem;
    }

  }

  .nav-link.active {
    background: transparent;
    font-weight: 900;

    &::after {
      @include triangle(right, $gray-200, 10px);
      height: 15px;
      width: 15px;
      right: -($grid-gutter-width / 2);
      position: absolute;
    }
  }
}
