footer.footer {
  background: $primary;
  color: $white;
  position: relative;

  @include media-breakpoint-up(md) {
    padding-bottom: 3.5rem;
  }

  @include media-breakpoint-up(xl) {
    padding-bottom: 5rem;
	}

  a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }

  .container-fluid::before {
    content: "";
    @include triangle(bottom, $white, 10px);
    position: absolute;
    top:0;
  }
}

.footer-logo {
  margin-bottom: 1.5rem;
  img {
    width: 170px;
  }
}

.footer-social a {
  font-size: 14px;
  border: 1px solid $white;
  border-radius: 50%;
  padding: 7px;
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  margin-right: 0.5rem;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    background: $white;
    color: $primary;
  }
}

footer.footer h5 {
  text-transform: uppercase;
  position: relative;
  padding-bottom: 0.5rem;
  margin-bottom: 0.75rem;
  border-bottom: 2px solid $gray-400;
  display: inline-block;
}

footer.footer form {
  margin-top: 0.35rem;

  .form-control {
    border: 0;
    background-color: rgba($white, 0.1);
    color: $white;
  }
}

footer.footer [class^="col"] {
  margin-bottom: 2rem;
}

footer.footer nav ul li > span {
  @include monospace(0.7rem);
  margin-right: 0.25rem;
}

// Cookies banner
#cookies {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  padding: 0.6em 1em;
  // display: flex;
  // flex-wrap: nowrap;
  overflow: hidden;
  // flex-direction: column;
  background: rgba(#000, 0.75);
  width: 100%;
  align-items: center;
  border-top: 1px solid rgba(#fff, 0.3);
  border-bottom: 1px solid rgba(#fff, 0.3);
  text-align: center;
  line-height: 1.5;
  color: #fff;

  .cookie-info a {
    text-decoration: underline;
    color: #fff;
  }

  a.cookie-dismiss {
    margin-top: 0;
    margin-left: 15px;
    font-size: 14px;
    letter-spacing: 0.1em;
    padding: 1px 9px;
  }
}


.registration-link{
  display: block;
  float: right;
  margin-top: 6px;
  margin-right: 5px;
}

@include media-breakpoint-down(sm) {
  .registration-link {
    float: none;
  }
}
