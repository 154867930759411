.offcanvas-menu {
  padding: 1rem;
  font-size: 1.25rem;

  .logo {
    padding: 0;

    img {
      width: 130px;
      margin-bottom: 0.6rem;
      padding: 0;
      opacity: 0.92;
    }
  }

  li.pushy-submenu > button {
    display: block;
    text-transform: uppercase;
    width: 100%;
    margin: 0;
    text-align: left;
    font-size: 1.25rem;
  }

  ul {
    list-style: none;
    padding: 0;

    .pushy-submenu ul {
      font-size: 1.2rem;
      padding-left: 1.1rem;
      border-bottom: 1px solid $gray-900;

      &:last-child {
        border: 0;
      }
    }

    .pushy-submenu ul li:last-child {
      margin-bottom: 0.8rem;
    }

    .pushy-submenu ul a {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .pushy-submenu .secondary-nav-subtitle {
      font-size: 1rem;
      text-transform: none;
      color: $gray-600;
      margin-bottom: 0;
      margin-top: 0.8rem;
      padding-bottom: 0.2rem;
      font-weight: $font-weight-normal;
    }
  }

  .offcanvas-search {
    .form-control {
      background: transparent;
      width: 90%;
      padding: 0.1rem 0;
      border-bottom: 2px solid $gray-200;
      color: $white;
    }

    .btn-default {
      background: transparent;
      border: 0;
      padding: 0;
      color: $gray-200;
    }
  }
}
