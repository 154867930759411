.search-template .search-form {
  margin-bottom: 3rem;

  .form-control {
    max-width: 200px;
    border: 2px solid $primary;
    display: inline-block;
    vertical-align: middle;
    @include media-breakpoint-up(md) {
      max-width: 250px;
    }
  }
}