body.withsidebar-template {
  .main {
    @include media-breakpoint-up(md) {
      border-left: 1px solid $gray-200;
      padding-left: $grid-gutter-width * 1.5;
    }
  }
}

.default-template,
.home-template {
  .content-block,
  .content-block-form_formframework {
    margin-bottom: 3rem;
    max-width: 46rem;
    margin-left: auto;
    margin-right: auto;

    h1,
    h2,
    h3,
    h4 {
      text-align: left;
    }

    &.content-block--text-image {
      max-width: none;

      @include media-breakpoint-up(lg) {
        h1,
        h2,
        h3,
        h4 {
          text-align: left;
        }
      }
    }
  }
}

.download-list-link {
  display: flex;
  width: 100%;
  padding: 3px 3px 3px 10px;
  border: 1px solid black;
  margin-bottom: 5px;
  word-break: break-all;
  align-items: center;

  i.fa {
    margin-right: 10px;
  }

  .download-title {
    line-height: 1.2;
  }

  &:hover {
    background-color: #000;
    color: #fff;
    text-decoration: none;
  }
}
