// scroll up
#scrollUp {
  bottom: 10px;
  right: 10px;
  height: 38px;
  width: 38px;
  background-color: $primary;
  border-bottom: 0;
  text-align: center;
  color: #fff;

  @include media-breakpoint-up(lg) {
    bottom: 25px;
    right: 25px;
  }

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }

  &::before {
    @include font-awesome-as-content;
    position: absolute;
    color: white;
    top: 6px;
    right: 0;
    left: 0;
    font-size: 12px;
    content: "\f077";
  }
}
