.breadcrumb-section {
  @include monospace(12px);
  border-bottom: 1px solid rgba($gray-200, 0.8);

  li a {
    color: $gray-800;

    &:hover {
      color: $link-color;
      text-decoration: none;
    }
  }

// sass-lint:disable-all
  li.active {
    font-weight: 700;
  }
}
// sass-lint:enable-all
