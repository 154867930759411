// Include Fonts (font-face mixin)
// Order of the includes matters, and it is: normal, bold, italic, bold+italic.
@include font-face('Source Sans Pro', '../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-regular', 400, normal);
@include font-face('Source Sans Pro', '../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-italic', 400, italic);
@include font-face('Source Sans Pro', '../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-600', 600, normal);
@include font-face('Source Sans Pro', '../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-600italic', 600, italic);
@include font-face('Source Sans Pro', '../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-700', 700, normal);
@include font-face('Source Sans Pro', '../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-700italic', 700, italic);
@include font-face('Source Sans Pro', '../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-900', 900, normal);
@include font-face('Source Sans Pro', '../Fonts/source-sans-pro-v11-latin/source-sans-pro-v11-latin-900italic', 900, italic);

@include font-face('Source Code Pro', '../Fonts/source-code-pro-v7-latin/source-code-pro-v7-latin-regular', 400, normal);
@include font-face('Source Code Pro', '../Fonts/source-code-pro-v7-latin/source-code-pro-v7-latin-700', 700, normal);



body {
  @include antialiased;
  letter-spacing: 0.022rem;
}

//* Responsive font size
html {
  font-size: 0.8rem;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 0.92rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1rem;
  }
}

b,
strong {
  font-weight: $font-weight-bold;
}

%condensed {
  font-family: $headings-font-family;
  font-weight: 300;
  line-height: $headings-line-height;
}

h1,
h2,
h3 {
  text-transform: uppercase;
}

p {
  margin-bottom: 1.4rem;
  font-size: 14px;
  @include media-breakpoint-up(md) {
    font-size: 15px;
  }
}
