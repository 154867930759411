.bx-wrapper {
  margin: 0 0 1.2rem;
  max-height: calc(95vh - 1.6rem);
  overflow: hidden;
  @include breakpoint {
    margin: 1.6rem 1.6rem 0;
  }
  @include media-breakpoint-up(xl) {
    margin: 2.5rem 2.5rem 0;
  }
}

.header .header-item {
  position: relative;
  visibility: hidden;

  .header-item-bg {
    background-size: cover;
    height: 0;
    overflow: hidden;
    background-position: center bottom;
    background-color: $gray-100;
    position: relative;
    background-repeat: no-repeat;

    padding-bottom: 42%; // Height

    @include breakpoint {
      padding-bottom: 35%;
    }

    .home-template & {
      padding-bottom: 52%;

      @include breakpoint {
        padding-bottom: 47%;
      }
    }
  }
}

// Pager
.header .header-item-text .pager {
  @include monospace(0.75rem);

  span {
    margin-left: 5px;
    margin-right: 5px;
  }
}

// General slide
.header .header-item-text {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 55%;
  max-width: 38rem;
  text-align: right;

  @include media-breakpoint-up(md) {
    width: 50%;
  }

  @include media-breakpoint-up(lg) {
    width: 40%;
  }

  .header-item-title {
    @include heading(2rem);
    line-height: 1;
    position: relative;

    @include media-breakpoint-up(sm) {
      @include heading(2.8rem);
    }

    @include media-breakpoint-up(md) {
      @include heading(3.2rem);
      line-height: 1;

      &::after {
        position: absolute;
        content: "";
        height: 1px;
        background-color: $primary;
        right: 0;
        left: -3rem;
        bottom: -1rem;
      }
    }
    @include media-breakpoint-up(xl) {
      @include heading(4.5rem);
      line-height: 1;
    }
  }
}

// Product slide
.header .header-item-text.product-item {
  .header-item-cat {
    @include monospace(0.75rem);
    margin-top: 1.5rem;
  }
}

body.home-template,
body.default-template {
  #navigation.sticky {
    position: fixed;
    transform: translateY(-20px);
    transition: all 0.5s ;
    top: 20px;
    background-color: rgba(255,255,255,1);
  }
}

body.noheader-template {
  padding-top: 135px;

  #navigation {
    position: fixed;
    top: 20px;
    margin-top: 0;
  }
}

@media (min-width: 900px) {
  body.noheader-template #navigation.sticky {
    position: fixed;
    transform: translateY(-20px);
    transition: all 0.5s;
    background-color: rgba(255,255,255,1);
  }
}
