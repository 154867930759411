.section--frontpage-news {
  h1 {
    margin-bottom: 1.4rem;
  }
}

// home isotope 4 cols
@include media-breakpoint-up(lg) {
  .home-template,
  .facts-template,
  .search-template {
    .grid-sizer,
    .grid-item {
      width: 22.7%;
    }
  }
}
