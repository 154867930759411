// Grid Sizes [start]
.grid-sizer,
.grid-item {
  width: 48.5%;
}

.gutter-sizer {
  width: 3%;
}

.grid-item {
  margin-bottom: 3%;
}

@include media-breakpoint-up(lg) {
  .grid-sizer,
  .grid-item {
    width: 31.33333%;
  }
}
// Grid Sizes [end]

.grid-item {
  position: relative;
  overflow: hidden;
  background-color: #e3e7ec;
}

// Images
.grid-item-img img {
  display: block;
  height: auto;
  width: 100%;
}

// Title
.grid-item-title {
  @include heading(0.92rem);
  display: inline-block;
  background-color: $primary;
  color: $white;
  padding: 0.5rem 0.65rem;
  position: absolute;
  left: 0;
  bottom: 1.5rem;
  z-index: 100;

  @include media-breakpoint-up(xl) {
    @include heading(1.05rem);
    color: $white;
    padding: 0.5rem 0.85rem;
  }
}

// Badge
.grid-item-badge {
  @include heading(1rem);
  border: 2px solid $primary;
  border-top: 0;
  position: absolute;
  top: 0;
  right: 1.2rem;
  padding: 0.55rem;
  text-align: center;

  > span {
    display: block;
  }
}


// Overlay
.grid-item-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0.85);
  border: 8px solid rgba($white, 0.1);
  overflow: hidden;
  color: $white;
  opacity: 0;

  // Diagonal white shine
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 50%;
    background: $white;
    transform: skew(-35deg);
    opacity: 0.03;
  }

  .grid-item-overlay-title {
    @include heading(2.2rem);
  }

  .grid-item-overlay-ean {
    font-size: 1.2rem;
    color: $gray-500;
    line-height: 1.2;
  }

  .grid-item-overlay-cat {
    @include monospace(12px);
  }

  .grid-item-overlay-title {
    text-align: center;
  }
}
